import React, { useState, useRef, useEffect } from 'react';
import { IoArrowForwardOutline, IoRefreshOutline, IoSearch, IoChevronDown } from 'react-icons/io5';
import { IPrimeiraSection } from "../../../dados/interfaces/contato";
import { useForm, Controller } from 'react-hook-form';
import DOMPurify from 'dompurify';
import { apiClient } from '../../../dados/api-client';
import InputMask from 'react-input-mask';
import { useQuery } from 'react-query';

interface IProps {
    dados: IPrimeiraSection
}

interface IRegiao {
    id: string;
    city: string;
    uf: string;
}

const SearchableSelect = ({ 
    options, 
    value, 
    onChange, 
    onChangeText,
    placeholder = "Selecione...",
    isLoading,
    error
}: {
    options: IRegiao[],
    value: string,
    onChange: (value: string) => void,
    onChangeText: (text: string) => void,
    placeholder?: string,
    isLoading?: boolean,
    error?: any
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const filteredOptions = options.filter(option => 
        option.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.uf.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const selectedOption = options.find(opt => opt.id === value);

    return (
        <div ref={wrapperRef} className="relative w-full">
            <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                disabled={isLoading}
                className={`
                    w-full px-3 py-2 border border-gray-300 rounded-md
                    flex items-center justify-between
                    ${isLoading ? 'bg-gray-50' : 'bg-white'}
                    text-left
                `}
            >
                {isLoading ? (
                    <span className="text-gray-500">Carregando...</span>
                ) : selectedOption ? (
                    `${selectedOption.city} - ${selectedOption.uf}`
                ) : (
                    <span className="text-gray-500">{placeholder}</span>
                )}
                <IoChevronDown className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />
            </button>

            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                    <div className="p-2 border-b">
                        <div className="relative">
                            <IoSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Pesquisar..."
                                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                    </div>
                    <div className="max-h-60 overflow-auto">
                        {filteredOptions.length === 0 ? (
                            <div className="p-3 text-center text-gray-500">
                                Nenhuma cidade encontrada
                            </div>
                        ) : (
                            filteredOptions.map((option) => (
                                <button
                                    key={option.id}
                                    type="button"
                                    onClick={() => {
                                        onChange(option.id);
                                        onChangeText(`${option.city} - ${option.uf}`);
                                        setIsOpen(false);
                                        setSearchTerm('');
                                    }}
                                    className={`
                                        w-full px-3 py-2 text-left hover:bg-gray-100
                                        ${value === option.id ? 'bg-gray-50' : ''}
                                    `}
                                >
                                    {option.city} - {option.uf}
                                </button>
                            ))
                        )}
                    </div>
                </div>
            )}
            {error && <p className="mt-1 text-sm text-red-600">Erro ao carregar as cidades</p>}
        </div>
    );
};

const fetchRegioes = async () => {
    const response = await apiClient('/localizacao', {
        method: 'GET',
    });
    return response;
};

const CConoscoSegundaSection = React.memo((props: IProps) => {
    const { data: regioes = [], isLoading: isLoadingRegioes, error: regioesError } = useQuery<IRegiao[]>(
        'localizacao',
        fetchRegioes
    );

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [apiError, setApiError] = useState<string | null>(null);
    const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
    const [curriculoFile, setCurriculoFile] = useState<string | null>(null);
    const [selectedRegiaoText, setSelectedRegiaoText] = useState<string>('');

    const { control, handleSubmit, formState: { errors, isSubmitting }, reset, setValue } = useForm({
        defaultValues: {
            nome: '',
            email: '',
            mensagem: '',
            whatsapp: '',
            curriculo: '',
            regiao: ''
        },
        mode: 'onBlur',
    });

    useEffect(() => {
        setValue('curriculo', curriculoFile || '');
    }, [curriculoFile, setValue]);

    const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = (reader.result as string).split(',')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const onSubmit = async (data: any) => {
        try {
            setSuccessMessage(null);
            setApiError(null);

            const purifiedData = {
                nome: DOMPurify.sanitize(data.nome),
                email: DOMPurify.sanitize(data.email),
                mensagem: DOMPurify.sanitize(data.mensagem),
                whatsapp: DOMPurify.sanitize(data.whatsapp),
                curriculo: curriculoFile || null,
                regiao: selectedRegiaoText // Usando o estado selectedRegiaoText
            };

            await apiClient('/conosco/cadastrar', {
                method: 'POST',
                body: purifiedData,
            });

            setSuccessMessage('Sua mensagem foi enviada com sucesso!');

            reset({
                nome: '',
                email: '',
                mensagem: '',
                whatsapp: '',
                curriculo: '',
                regiao: ''
            });
            setCurriculoFile(null);
            setSelectedFileName(null);
            setSelectedRegiaoText('');

            const fileInput = document.getElementById('curriculo') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        } catch (error) {
            setApiError('Não foi possível enviar sua mensagem, tente novamente mais tarde.');
            console.error(error);
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.type !== 'application/pdf') {
                setApiError('Apenas arquivos PDF são permitidos.');
                return;
            }

            if (file.size > 5 * 1024 * 1024) {
                setApiError('O arquivo deve ter no máximo 5MB.');
                return;
            }

            try {
                const base64File = await convertFileToBase64(file);
                setCurriculoFile(base64File);
                setValue('curriculo', base64File);
                setSelectedFileName(file.name);
                setApiError(null);

                if (event.target) {
                    event.target.value = '';
                }
            } catch (error) {
                setApiError('Erro ao converter o arquivo.');
                console.error(error);
            }
        }
    };

    const handleFileRemove = () => {
        setValue('curriculo', '');
        setCurriculoFile(null);
        setSelectedFileName(null);

        const fileInput = document.getElementById('curriculo') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    return (
        <section className="flex flex-col justify-center items-center gap-10 py-20 bg-white px-5 xl:px-0">
            <div className="flex flex-col gap-10 max-w-7xl relative w-full border border-gray-300 rounded-xl p-10">
                <div className="flex flex-col gap-5">
                    <p className="text-[30px] text-black font-bold">{props.dados.tituloForm}</p>
                    <p className="text-[20px] text-black font-normal">{props.dados.textoForm}</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-10'>
                    <div className='flex flex-col lg:flex-row justify-between gap-10'>
                        <div className='flex flex-col w-full gap-3'>
                            <label htmlFor="nome" className='text-[18px] text-black font-medium'>Nome</label>
                            <Controller
                                name="nome"
                                control={control}
                                rules={{ required: 'Nome é um campo obrigatório' }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        id="nome"
                                        type="text"
                                        className='px-3 py-2 border border-gray-300 outline-none rounded-md'
                                    />
                                )}
                            />
                            {errors.nome && <p className='text-red-700 border-l-4 border-red-500 px-5 py-1w-auto self-start'>{errors.nome.message}</p>}
                        </div>

                        <div className='flex flex-col w-full gap-3'>
                            <label htmlFor="email" className='text-[18px] text-black font-medium'>E-mail</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'E-mail é um campo obrigatório',
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: 'E-mail inválido'
                                    }
                                }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        id="email"
                                        type="email"
                                        className='px-3 py-2 border border-gray-300 outline-none rounded-md'
                                    />
                                )}
                            />
                            {errors.email && <p className='text-red-700 border-l-4 border-red-500 px-5 py-1w-auto self-start'>{errors.email.message}</p>}
                        </div>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <label htmlFor="whatsapp" className='text-[18px] text-black font-medium'>Whatsapp</label>
                        <Controller
                            name="whatsapp"
                            control={control}
                            rules={{ required: 'Whatsapp é um campo obrigatório' }}
                            render={({ field }) => (
                                <InputMask
                                    {...field}
                                    id="whatsapp"
                                    type="text"
                                    mask="(99) 99999-9999"
                                    className='px-3 py-2 border border-gray-300 outline-none rounded-md'
                                />
                            )}
                        />
                        {errors.whatsapp && <p className='text-red-700 border-l-4 border-red-500 px-5 py-1w-auto self-start'>{errors.whatsapp.message}</p>}
                    </div>

                    <div className='flex flex-col gap-3'>
                        <label htmlFor="mensagem" className='text-[18px] text-black font-medium'>Mensagem</label>
                        <Controller
                            name="mensagem"
                            control={control}
                            rules={{ required: 'Mensagem é um campo obrigatório' }}
                            render={({ field }) => (
                                <textarea
                                    {...field}
                                    id="mensagem"
                                    className='px-3 py-2 resize-none h-[200px] border border-gray-300 outline-none rounded-md'
                                />
                            )}
                        />
                        {errors.mensagem && <p className='text-red-700 border-l-4 border-red-500 px-5 py-1w-auto self-start'>{errors.mensagem.message}</p>}
                    </div>

                    <div className='flex flex-col gap-3'>
                        <label htmlFor="regiao" className='text-[18px] text-black font-medium'>Cidade - UF</label>
                        <Controller
                            name="regiao"
                            control={control}
                            rules={{ required: 'Cidade é um campo obrigatório' }}
                            render={({ field }) => (
                                <SearchableSelect
                                    options={regioes}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onChangeText={setSelectedRegiaoText}
                                    placeholder="Selecione uma cidade"
                                    isLoading={isLoadingRegioes}
                                    error={regioesError}
                                />
                            )}
                        />
                        {errors.regiao && (
                            <p className='text-red-700 border-l-4 border-red-500 px-5 py-1 w-auto self-start'>
                                {errors.regiao.message}
                            </p>
                        )}
                    </div>

                    <div className='flex flex-col gap-3'>
                        <label htmlFor="curriculo" className='text-[18px] text-black font-medium'>Currículo (PDF) - Opcional</label>
                        <div className='flex items-center gap-3'>
                            <input
                                id="curriculo"
                                type="file"
                                accept=".pdf"
                                onChange={handleFileChange}
                                className='hidden'
                            />
                            <label
                                htmlFor="curriculo"
                                className='px-4 py-2 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-100'
                            >
                                Escolher arquivo
                            </label>
                            {selectedFileName && (
                                <div className='flex items-center gap-2'>
                                    <span className='text-gray-600 truncate max-w-[200px]'>
                                        {selectedFileName}
                                    </span>
                                    <button
                                        type="button"
                                        onClick={handleFileRemove}
                                        className='text-red-500 hover:text-red-700'
                                    >
                                        ×
                                    </button>
                                </div>
                            )}
                        </div>
                        <p className='text-xs text-gray-500'>Opcional. Apenas PDFs, máximo 5MB</p>
                    </div>

                    {apiError && <p className='text-red-700 border-l-4 border-red-500 px-5 py-1w-auto self-start'>{apiError}</p>}
                    {successMessage && <p className='text-green-700 border-l-4 border-green-500 px-5 py-1w-auto self-start'>{successMessage}</p>}

                    <button
                        type="submit"
                        className="flex flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-blue-700 hover:bg-opacity-70 self-end"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                <p className="text-white text-center text-sm font-medium">Enviando mensagem</p>
                                <IoRefreshOutline className='text-white animate-spin' />
                            </>
                        ) : (
                            <>
                                <p className="text-white text-center text-sm font-medium">Enviar mensagem</p>
                                <IoArrowForwardOutline className='text-white' />
                            </>
                        )}
                    </button>
                </form>
            </div>
        </section>
    )
});

export default CConoscoSegundaSection;